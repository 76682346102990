const FOpticaTel = [
  // {
  //   id: 1,
  //   megas: "10",
  //   titulomeg: "Megas",
  //   precio: "$350",
  //   tiempo: " /al mes",
  //   incluye: [
  //     "Servicio de fibra óptica",
  //     "Internet ilimitado",
  //     "Línea telefónica",
  //   ],
  //   titleButton: "Me interesa",
  //   hrefButton:
  //     "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 10 megas Fibra Óptica más Telefonía",
  //   styleCardHeader: [
  //     {
  //       id: 1,
  //       styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
  //       styleTextHead: "text-white text-7xl font-bold text-center p-1",
  //       styleMeg: "text-lg",
  //     },
  //   ],
  //   styleBodyCard: [
  //     {
  //       id: 1,
  //       styleContPrice:
  //         "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
  //       styleTextPrice: "text-4xl font-bold",
  //       styleMes: "text-lg",
  //       styleItemIcon: "text-casper",
  //     },
  //   ],
  //   styleFooter: [
  //     {
  //       id: 1,
  //       styleButton:
  //         "bg-casper text-white py-2 xl:w-80 md:w-72 sm:w-52 text-xl text-center font-bold rounded-lg",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   megas: "25",
  //   titulomeg: "Megas",
  //   precio: "$400",
  //   tiempo: " /al mes",
  //   incluye: [
  //     "Servicio de fibra óptica",
  //     "Internet ilimitado",
  //     "Línea telefónica",
  //   ],
  //   titleButton: "Me interesa",
  //   hrefButton:
  //     "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 25 megas Fibra Óptica más Telefonía",
  //   styleCardHeader: [
  //     {
  //       id: 1,
  //       styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
  //       styleTextHead: "text-white text-7xl font-bold text-center p-1",
  //       styleMeg: "text-lg",
  //     },
  //   ],
  //   styleBodyCard: [
  //     {
  //       id: 1,
  //       styleContPrice:
  //         "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
  //       styleTextPrice: "text-4xl font-bold",
  //       styleMes: "text-lg",
  //       styleItemIcon: "text-casper",
  //     },
  //   ],
  //   styleFooter: [
  //     {
  //       id: 1,
  //       styleButton:
  //         "bg-casper text-white py-2 xl:w-80 md:w-72 sm:w-52 text-xl text-center font-bold rounded-lg",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   megas: "35",
  //   titulomeg: "Megas",
  //   precio: "$450",
  //   tiempo: " /al mes",
  //   incluye: [
  //     "Servicio de fibra óptica",
  //     "Internet ilimitado",
  //     "Línea telefónica",
  //   ],
  //   titleButton: "Me interesa",
  //   hrefButton:
  //     "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 35 megas Fibra Óptica más Telefonía",
  //   styleCardHeader: [
  //     {
  //       id: 1,
  //       styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
  //       styleTextHead: "text-white text-7xl font-bold text-center p-1",
  //       styleMeg: "text-lg",
  //     },
  //   ],
  //   styleBodyCard: [
  //     {
  //       id: 1,
  //       styleContPrice:
  //         "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
  //       styleTextPrice: "text-4xl font-bold",
  //       styleMes: "text-lg",
  //       styleItemIcon: "text-casper",
  //     },
  //   ],
  //   styleFooter: [
  //     {
  //       id: 1,
  //       styleButton:
  //         "bg-casper text-white py-2 xl:w-80 md:w-72 sm:w-52 text-xl text-center font-bold rounded-lg",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   megas: "50",
  //   titulomeg: "Megas",
  //   precio: "$500",
  //   tiempo: " /al mes",
  //   incluye: [
  //     "Servicio de fibra óptica",
  //     "Internet ilimitado",
  //     "Línea telefónica",
  //   ],
  //   titleButton: "Me interesa",
  //   hrefButton:
  //     "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 50 megas Fibra Óptica más Telefonía",
  //   styleCardHeader: [
  //     {
  //       id: 1,
  //       styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
  //       styleTextHead: "text-white text-7xl font-bold text-center p-1",
  //       styleMeg: "text-lg",
  //     },
  //   ],
  //   styleBodyCard: [
  //     {
  //       id: 1,
  //       styleContPrice:
  //         "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
  //       styleTextPrice: "text-4xl font-bold",
  //       styleMes: "text-lg",
  //       styleItemIcon: "text-casper",
  //     },
  //   ],
  //   styleFooter: [
  //     {
  //       id: 1,
  //       styleButton:
  //         "bg-casper text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
  //     },
  //   ],
  // },
  {
    id: 1,
    megas: "60",
    titulomeg: "Megas",
    precio: "$600",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Línea telefónica",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 60 megas Fibra Óptica más Telefonía",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-casper",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-casper text-white py-2 xl:w-80 md:w-72 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 2,
    megas: "90",
    titulomeg: "Megas",
    precio: "$700",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Línea telefónica",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 90 megas Fibra Óptica más Telefonía",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-casper",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-casper text-white py-2 xl:w-80 md:w-72 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 3,
    megas: "120",
    titulomeg: "Megas",
    precio: "$900",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Línea telefónica",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 120 megas Fibra Óptica más Telefonía",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-casper",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-casper text-white py-2 xl:w-80 md:w-72 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 4,
    megas: "150",
    titulomeg: "Megas",
    precio: "$1000",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Línea telefónica",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 150 megas Fibra Óptica más Telefonía",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-casper rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-casper",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-casper text-white py-2 xl:w-80 md:w-72 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
];

export default FOpticaTel;
