const FOptica = [
  {
    id: 1,
    megas: "15",
    titulomeg: "Megas",
    precio: "$300",
    tiempo: " /al mes",
    incluye: ["Servicio de fibra óptica", "Internet ilimitado"],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 10 megas Fibra Óptica",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-viking rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-viking",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-viking text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 2,
    megas: "30",
    titulomeg: "Megas",
    precio: "$350",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Rafaga de 5MBS",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 30 megas Fibra Óptica",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-viking rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-viking",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-viking text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 3,
    megas: "40",
    titulomeg: "Megas",
    precio: "$400",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Rafaga de 5MBS",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 40 megas Fibra Óptica",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-viking rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-viking",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-viking text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  // {
  //   id: 4,
  //   megas: "55",
  //   titulomeg: "Megas",
  //   precio: "$450",
  //   tiempo: " /al mes",
  //   incluye: ["Servicio de fibra óptica", "Internet ilimitado"],
  //   titleButton: "Me interesa",
  //   hrefButton:
  //     "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 55 megas Fibra Óptica",
  //   styleCardHeader: [
  //     {
  //       id: 1,
  //       styleHeader: "w-full md:h-24 bg-viking rounded-t-lg",
  //       styleTextHead: "text-white text-7xl font-bold text-center p-1",
  //       styleMeg: "text-lg",
  //     },
  //   ],
  //   styleBodyCard: [
  //     {
  //       id: 1,
  //       styleContPrice:
  //         "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
  //       styleTextPrice: "text-4xl font-bold",
  //       styleMes: "text-lg",
  //       styleItemIcon: "text-viking",
  //     },
  //   ],
  //   styleFooter: [
  //     {
  //       id: 1,
  //       styleButton:
  //         "bg-viking text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
  //     },
  //   ],
  // },
  {
    id: 5,
    megas: "70",
    titulomeg: "Megas",
    precio: "$500",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Rafaga de 5MBS",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 70 megas Fibra Óptica",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-viking rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-viking",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-viking text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  // {
  //   id: 6,
  //   megas: "90",
  //   titulomeg: "Megas",
  //   precio: "$550",
  //   tiempo: " /al mes",
  //   incluye: ["Servicio de fibra óptica", "Internet ilimitado"],
  //   titleButton: "Me interesa",
  //   hrefButton:
  //     "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 90 megas Fibra Óptica",
  //   styleCardHeader: [
  //     {
  //       id: 1,
  //       styleHeader: "w-full md:h-24 bg-viking rounded-t-lg",
  //       styleTextHead: "text-white text-7xl font-bold text-center p-1",
  //       styleMeg: "text-lg",
  //     },
  //   ],
  //   styleBodyCard: [
  //     {
  //       id: 1,
  //       styleContPrice:
  //         "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
  //       styleTextPrice: "text-4xl font-bold",
  //       styleMes: "text-lg",
  //       styleItemIcon: "text-viking",
  //     },
  //   ],
  //   styleFooter: [
  //     {
  //       id: 1,
  //       styleButton:
  //         "bg-viking text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
  //     },
  //   ],
  // },
  {
    id: 7,
    megas: "100",
    titulomeg: "Megas",
    precio: "$700",
    tiempo: " /al mes",
    incluye: [
      "Servicio de fibra óptica",
      "Internet ilimitado",
      "Rafaga de 5MBS",
    ],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527731633306&text=Hola me interesa el paquete de 120 megas Fibra Óptica",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-viking rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-cerulean text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-viking",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-viking text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
];

export default FOptica;
